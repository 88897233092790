/** @jsx jsx */
import { css } from '@emotion/react'

export const wrapperListStyle = css`
  display: flex;
  align-items: center;
  position: relative;
`

export const lockTimeLeftStyle = css`
  color: var(--error-color);
  padding-top: 2px;
  font-size: 12px;
  width: 50px;
`
