import { useLayoutEffect, useRef, useState } from 'react'

interface CountDownConfig {
  time: number
}

const TIMER_TICK_MS = 1000
const withExtraZero = (val: number) => (val <= 9 ? `0${val}` : `${val}`)

const getTime = (t: number) => ({
  years: withExtraZero(Math.floor(t / (1000 * 60 * 60 * 24 * 30 * 12))),
  months: withExtraZero(Math.floor((t / (1000 * 60 * 60 * 24 * 30)) % 12)),
  days: withExtraZero(Math.floor((t / (1000 * 60 * 60 * 24)) % 30)),
  hours: withExtraZero(Math.floor((t / (1000 * 60 * 60)) % 24)),
  minutes: withExtraZero(Math.floor((t / (1000 * 60)) % 60)),
  seconds: withExtraZero(Math.floor((t / 1000) % 60)),
})

export const useCountDown = (config: CountDownConfig) => {
  const intervalRef = useRef<number>()

  const [time, setTime] = useState<number>(() => {
    const diff = config.time - new Date().getTime()
    return diff > 0 ? diff : 0
  })

  useLayoutEffect(() => {
    const timerTick = () => {
      const currentTime = new Date().getTime()
      const diffTime = config.time - currentTime
      const isLeftTime = diffTime < 0

      if (isLeftTime) {
        setTime(0)
        clearInterval(intervalRef.current)
        return
      }

      setTime(diffTime)
    }

    intervalRef.current = window.setInterval(timerTick, TIMER_TICK_MS)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [config.time])

  return {
    isTimeLeft: time === 0,
    ...getTime(time),
  }
}
