export const localesEN = {
    lockError: 'Lock error',
    alreadyLockError: 'The camera is blocked by another user!',
    changeLockError: 'Failed to change lock status',
    commonError: 'Error',
    block: 'Block',
    unLock: 'Unlock',
    extend: 'Extend',
    forTenMinutes: 'for 10 minutes',
    forThirtyMinutes: 'for 30 minutes',
    forSixtyMinutes: 'for 60 minutes'
}
