export const localesRU = {
    lockError: 'Ошибка блокировки',
    alreadyLockError: 'Камера заблокирована другим пользователем!',
    changeLockError: 'Не удалось сменить статус блокировки',
    commonError: 'Ошибка',
    block: 'Заблокировать',
    unLock: 'Разблокировать',
    extend: 'Продлить',
    forTenMinutes: 'на 10 минут',
    forThirtyMinutes: 'на 30 минут',
    forSixtyMinutes: 'на 60 минут'
}
