import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ISearchQueryPayload, TUnionRepo } from '@netvision/lib-api-repo'
import { useApiRepository } from '../hooks'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'
import { name as reactWidgetName } from '../../package.json'

const cameraCommands = {
  lockControl: 'lockControl',
  unlockControl: 'unlockControl',
  lockStreams: 'lockStreams',
  unlockStreams: 'unlockStreams',
} as const

enum CameraPermissionScopes {
  LockControl = 'LockControl',
  UnlockControl = 'UnlockControl',
  UnlockStreams = 'UnlockStreams',
  LockStreams = 'LockStreams',
}

export interface CameraLocking {
  id: string
  type: 'CameraLocking'
  allowedGroups: string[]
  allowedUsers: string[]
  cameraId: string
  createdStreams: []
  periodInSeconds: number
  endDt: null | number
  ownerId: string
  scopes: keyof typeof cameraCommands
  startDt: number
  state: 'Creation' | 'Active' | 'Canceled' | 'Error' | 'Finished'
}

const isLibApiRepo = (api: TUnionRepo): api is LibApiRepository => 'getNotificationSocket' in api

export type CameraBlockCommands = keyof typeof cameraCommands

const isLockedControls = (permissionsScopes?: CameraPermissionScopes[]) =>
  permissionsScopes?.includes(CameraPermissionScopes.LockControl) &&
  permissionsScopes?.includes(CameraPermissionScopes.UnlockControl)

const isLockedStreams = (permissionsScopes?: CameraPermissionScopes[]) =>
  permissionsScopes?.includes(CameraPermissionScopes.UnlockStreams) &&
  permissionsScopes?.includes(CameraPermissionScopes.LockStreams)

const isActiveOrCreationState = (cameraLocking: CameraLocking | null) => {
  if (!cameraLocking) return false
  return (['Creation', 'Active'] as CameraLocking['state'][]).includes(cameraLocking?.state)
}

const isStreamLockedNow = (cameraLocking: CameraLocking | null) =>
  [cameraCommands.lockStreams].every((c) => cameraLocking?.scopes.includes(c)) &&
  isActiveOrCreationState(cameraLocking)

const isControlsLockedNow = (cameraLocking: CameraLocking | null) => {
  return (
    [cameraCommands.lockControl].every((c) => cameraLocking?.scopes.includes(c)) &&
    isActiveOrCreationState(cameraLocking)
  )
}

const buildCameraCommand = async (
  api: TUnionRepo,
  command: string,
  cameras?: string[],
  commandExtraData?: Record<string, number | string>,
) => {
  if (!isLibApiRepo(api)) return
  if (!cameras || !cameras.length) return
  const promises = cameras.map((id) => api.updateEntity({
    id,
    type: 'Camera',
    [command]: {
      ...commandExtraData
    }
  }))

  return await Promise.all(promises)
}

const getLockedInfo = (
  locked: (periodInSeconds: number) => Promise<void>,
  unlocked: () => Promise<void>,
  permissionsScopes: null | Map<string, { scopes: CameraPermissionScopes[] }>,
  cameraLocking: null | CameraLocking[],
  cameraIds: string[],
  loading: boolean,
) => {
  const hasOnlyOneLockingData = Array.isArray(cameraLocking) && cameraLocking.length === 1
  return {
    isLoading: loading || !permissionsScopes,
    lockingData: hasOnlyOneLockingData
      ? {
          id: cameraLocking[0]?.id,
          status: cameraLocking[0]?.state,
          period: (cameraLocking[0]?.periodInSeconds || 0) * 1000,
          start: cameraLocking[0].startDt || 0,
        }
      : null,
    ...getPermissionInfo(permissionsScopes, cameraLocking, cameraIds),
    locked,
    unlocked,
  }
}

export type LockingInfoType = Omit<ReturnType<typeof getLockedInfo>, 'locked' | 'unlocked'>

const getPermissionInfo = (
  permissionsScopes: null | Map<string, { scopes: CameraPermissionScopes[] }>,
  cameraLocking: null | CameraLocking[],
  cameraIds: string[],
) => {
  const changeLockedControls =
    permissionsScopes &&
    permissionsScopes?.size > 0 &&
    cameraIds.length > 0 &&
    cameraIds?.every((id) => isLockedControls(permissionsScopes?.get(id)?.scopes))
  const watchLockedStreams =
    permissionsScopes &&
    permissionsScopes?.size > 0 &&
    cameraIds.length > 0 &&
    cameraIds?.every((id) => isLockedStreams(permissionsScopes?.get(id)?.scopes))

  return {
    canI: {
      someLocked: watchLockedStreams || changeLockedControls,
      watchLockedStreams,
      changeLockedControls,
    },
    is: {
      streamLockedNow: cameraLocking?.some(isStreamLockedNow),
      controlsLockedNow: cameraLocking?.some(isControlsLockedNow),
    },
  }
}

export const LockContext = createContext<ReturnType<typeof getLockedInfo>>(null!)

export interface LockProviderProps {
  onChangeLockingInfo?: (cameraLockingInfo: LockingInfoType) => void,
  children: React.ReactNode,
  cameraIds: string[]
  hideTimer?: boolean
}

export const LockProvider = (props: LockProviderProps) => {
  const userId = useRef('')
  const cameraIds = useMemo(() => [...new Set(props.cameraIds.filter(Boolean))] || [], [props.cameraIds])

  const { api } = useApiRepository()

  const [loading, setLoading] = useState(true)
  const [cameraLocking, setCameraLocking] = useState<null | CameraLocking[]>(null)
  const [permissionsScopes, setPermissionScopes] = useState<null | Map<
    string,
    { scopes: CameraPermissionScopes[] }
  >>(null)

  const commands = useMemo(
    () =>
      Object.fromEntries(
        Object.values(cameraCommands).map((commandName) => [
          commandName,
          buildCameraCommand.bind(null, api, commandName),
        ]),
      ) as Record<
        CameraBlockCommands,
        (
          cameras: string[],
          commandExtraData?: Record<string, number | string>,
        ) => ReturnType<typeof buildCameraCommand>
      >,
    [api],
  )

  const updateCameraLocking = useCallback(async () => {
    if (!isLibApiRepo(api)) return
    let userIdFromStorage = window.localStorage.getItem('netvision:user-id')

    if (!userIdFromStorage) {
      const { userId: idUser } = await api.getUserInfo()
      userIdFromStorage = idUser
    }

    userId.current = userIdFromStorage as string
    // @ts-ignore
    const { results } = (await api.getMergedQueryBatch<CameraLocking>({
      limiter: {
        type: 'CameraLocking',
      },
      filter: {
        q: [
          {
            key: 'cameraId',
            operator: '==' as ISearchQueryPayload['operator'],
            value: cameraIds.join(','),
          },
          {
            key: 'state',
            operator: '==',
            value: 'Active,Creation',
          },
        ],
      },
    })) as { results: CameraLocking[] }

    const filteredResults = results.filter(({ cameraId }) => cameraIds.includes(cameraId))
    return filteredResults
  }, [api, cameraIds])

  useEffect(() => {
    if (cameraIds.length === 0) return
    const getPermissionAndLockingEntity = async () => {
      // @ts-ignore
      if (!api.getPermissionWithGlobalBatch) return

      try {
        setLoading(true)
        // @ts-ignore
        const permissionMap = (await api.getPermissionWithGlobalBatch(
          cameraIds.map((id) => ({
            entityId: id,
            scopes: Object.values(CameraPermissionScopes),
          })),
          reactWidgetName,
        )) as Map<string, { scopes: CameraPermissionScopes[] }>

        setPermissionScopes(permissionMap)
      } catch (e) {
        setPermissionScopes(new Map())
        console.error(e)
      }

      try {
        const cameraLocking = await updateCameraLocking()

        if (!cameraLocking) {
          setCameraLocking(null)
          return
        }

        setCameraLocking(cameraLocking)
      } catch (e) {
        setCameraLocking(null)
        console.error(e)
      } finally {
        setLoading(false)
      }
    }

    getPermissionAndLockingEntity()
  }, [api, cameraIds, updateCameraLocking])

  const updateCameraLockingBySocket = useCallback(
    (cameraLockingData: CameraLocking) => {
      const hasThisId = cameraIds.includes(cameraLockingData.cameraId)
      if (!hasThisId) return
      setCameraLocking((prev) => {
        if (Array.isArray(prev)) {
          const indexCameraLocking = prev.findIndex(
            (data) => data.cameraId === cameraLockingData.cameraId,
          )

          if (indexCameraLocking !== -1) {
            return prev?.map((entity, index) => {
              if (index === indexCameraLocking) return { ...cameraLockingData }
              return entity
            })
          }

          return [...prev, cameraLockingData]
        }

        return [{ ...cameraLockingData }]
      })
    },
    [cameraIds],
  )

  useEffect(() => {
    if (!isLibApiRepo(api)) return
    const socket = api.getNotificationSocket()
    return socket.addListener('CameraLocking', updateCameraLockingBySocket)
  }, [api, updateCameraLockingBySocket])

  const locked = useCallback(
    async (periodInSeconds: number) => {
      if (!permissionsScopes) return
      const permissionInfo = getPermissionInfo(permissionsScopes, cameraLocking, cameraIds)
      const notMyLockedCamera = cameraLocking
        ?.filter((lockingInfo) => {
          const { ownerId } = lockingInfo
          if (permissionInfo.canI.watchLockedStreams && isControlsLockedNow(lockingInfo)) return false
          return ownerId !== userId.current
        })
        .map(({ cameraId }) => cameraId)

        const ownCameraId = cameraIds.filter((id) => !(notMyLockedCamera || []).includes(id))

      try {
        setLoading(true)
        if (permissionInfo.canI.watchLockedStreams) {
          await commands.lockStreams(ownCameraId, { periodInSeconds })
        }
        if (!permissionInfo.canI.watchLockedStreams && permissionInfo.canI.changeLockedControls) {
          await commands.lockControl(ownCameraId)
        }
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        setLoading(false)
      }
    },
    [cameraIds, cameraLocking, commands, permissionsScopes],
  )

  const unlocked = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      if (!permissionsScopes) return
      const makeUnlock = async () => {
        const permissionInfo = getPermissionInfo(permissionsScopes, cameraLocking, cameraIds)
        // @ts-ignore
        if (api?.addOnBadRequestListener) {
          // @ts-ignore
          const unsubscribe = api?.addOnBadRequestListener(async (response: Response) => {
            const isJsonResponse = response.headers.get('content-type')
            if (isJsonResponse?.includes('application/json;')) {
              const r = await response.json()
              reject(new Error(r.description))
            }
            unsubscribe()
          })
        }

        try {
          setLoading(true)
          let actualCameraLocking = cameraLocking
            ?.filter((data) => isStreamLockedNow(data) || isControlsLockedNow(data))
          if (!actualCameraLocking || !cameraLocking) return
          if (cameraLocking?.length > 1 || props?.hideTimer) {
            actualCameraLocking = actualCameraLocking.filter(({ ownerId }) => ownerId === userId.current)
          }
          const actualCamerasId = actualCameraLocking.map(({ cameraId }) => cameraId)
          if (permissionInfo.canI.watchLockedStreams) await commands.unlockStreams(actualCamerasId)
          if (!permissionInfo.canI.watchLockedStreams && permissionInfo.canI.changeLockedControls) {
            await commands.unlockControl(actualCamerasId)
          }
          resolve()
        } catch (e) {
          console.error(e)
          throw e
        } finally {
          setLoading(false)
        }
      }

      makeUnlock()
    })
  }, [api, cameraIds, cameraLocking, commands, permissionsScopes])

  const data = useMemo(
    () => getLockedInfo(locked, unlocked, permissionsScopes, cameraLocking, cameraIds, loading),
    [cameraIds, cameraLocking, loading, locked, permissionsScopes, unlocked],
  )

  useEffect(() => {
    if (!props?.onChangeLockingInfo) return
    const { locked, unlocked, ...rest } = data
    props?.onChangeLockingInfo(rest)
  }, [data])

  return <LockContext.Provider value={data}>{props.children}</LockContext.Provider>
}
