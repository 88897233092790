import React, { createContext, ReactNode, useMemo } from 'react'
import { repositoryGetter, TUnionRepo, TLibProp } from '@netvision/lib-api-repo'

export type TApiRepositoryProviderProps = {
  lib?: TLibProp
  children: ReactNode
}

const DEFAULT_LIB_API = {
  name: 'lib-api',
  mixins: ['cubejs'],
}

export const LibApiRepoProvider = ({ children, lib }: TApiRepositoryProviderProps) => {
  const api = useMemo(() => repositoryGetter(lib || DEFAULT_LIB_API) as TUnionRepo, [])
  const value = useMemo(() => ({ api }), [api])

  return <LibApiRepoContext.Provider value={value}>{children}</LibApiRepoContext.Provider>
}

export const LibApiRepoContext = createContext({
  api: {} as TUnionRepo,
})
