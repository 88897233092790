import React from 'react'
import { IWidgetProps } from '../IWidgetProps'

export const WidgetPropsContext = React.createContext<IWidgetProps>(null!)

type WidgetPropsProviderProps = {
  children: React.ReactNode
  widgetProps: IWidgetProps
}

export const WidgetPropsProvider = ({ children, widgetProps }: WidgetPropsProviderProps) => {
  return <WidgetPropsContext.Provider value={widgetProps}>{children}</WidgetPropsContext.Provider>
}
