import React from 'react'
import { IWidgetProps } from './IWidgetProps'
import { LibApiRepoProvider, LockProvider, WidgetPropsProvider, LocaleProvider } from './providers'
import { LockButton } from './modules'

export default function Root(widgetProps: IWidgetProps) {
  const { cameraIds, hideTimer, onChangeLockingInfo } = widgetProps.props!
  return (
    <WidgetPropsProvider widgetProps={widgetProps}>
      <LibApiRepoProvider lib={widgetProps.props?.lib}>
        <LocaleProvider>
          <LockProvider
            cameraIds={cameraIds}
            hideTimer={hideTimer}
            onChangeLockingInfo={onChangeLockingInfo}>
            <LockButton />
          </LockProvider>
        </LocaleProvider>
      </LibApiRepoProvider>
    </WidgetPropsProvider>
  )
}
