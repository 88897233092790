/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useMemo, useRef } from 'react'

import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { OverlayPanel } from 'primereact/overlaypanel'

import { useCameraLockedData, useCountDown, useLocale, useWidgetProps } from '../../hooks'

import { TieredList, TTieredItem, TieredItemValue } from '../../components'

import { lockTimeLeftStyle, wrapperListStyle } from './style'
import { createPortal } from 'react-dom'

import type { ILocale } from '../../locales/index'

const getDefaultLockData = (locale: ILocale) => {
  return {
    state: {
      lock: {
        icon: 'mdi-lock-outline',
      },
      unlock: {
        icon: 'mdi-lock-open-variant-outline',
      },
    },
    menu: [
      {
        id: 'locked-10',
        label: locale.forTenMinutes,
        value: 60 * 10,
      },
      {
        id: 'locked-30',
        label: locale.forThirtyMinutes,
        value: 60 * 30,
      },
      {
        id: 'locked-60',
        label: locale.forSixtyMinutes,
        value: 60 * 60,
      },
    ],
  }
}

const buildMenu = (isOnlyLockControls: boolean, locale: ILocale, menu?: Record<string, any>[]) => {
  const lockSlice = (menu || getDefaultLockData(locale).menu).map((item) => ({
    ...item,
    value: {
      type: 'lock',
      periodInSeconds: item.value,
    },
  })) as TTieredItem[]

  const lock: TTieredItem[] = isOnlyLockControls
    ? [
        {
          id: 'loked-control',
          label: locale.block,
          value: {
            type: 'lock',
            periodInSeconds: 0,
          },
        },
      ]
    : lockSlice

  const unlock: TTieredItem[] = [
    {
      id: 'unloked',
      label: locale.unLock,
      value: {
        type: 'unlock',
      },
    },
    isOnlyLockControls
      ? ({} as TTieredItem)
      : {
          id: 'extend',
          label: locale.extend,
          items: lock,
        },
  ]

  return {
    lock,
    unlock,
  }
}

export const LockButton = () => {
  const { locked, unlocked, is, canI, isLoading, lockingData } = useCameraLockedData()
  const locale = useLocale()
  const countDownConfig = useMemo(() => {
    const { period = 0, start = 0 } = lockingData || {}

    return {
      time: period === 0 ? 0 : start + period,
    }
  }, [lockingData])

  const { seconds, minutes, hours, isTimeLeft } = useCountDown(countDownConfig)
  const { props } = useWidgetProps()
  const {
    lockFeatureSettings,
    buttonClassName = 'p-button-text p-button-plain',
    hideTimer,
  } = props!

  const globalToast = useRef<Toast>(null!)
  const tieredList = useRef<OverlayPanel>(null!)
  const toastRef = globalToast

  useEffect(() => {
    if (lockingData?.status !== 'Error') return
    toastRef.current?.show({
      severity: 'error',
      detail: locale.lockError,
      life: 5000,
    })
  }, [locale.lockError, lockingData?.status, toastRef])

  const iconsClass = {
    lock: lockFeatureSettings?.state?.lock?.icon || getDefaultLockData(locale).state.lock.icon,
    unlock: lockFeatureSettings?.state?.unlock?.icon || getDefaultLockData(locale).state.unlock.icon,
  }

  const isBlockedStatus =
    canI.changeLockedControls && !canI.watchLockedStreams
      ? is.controlsLockedNow
      : canI.watchLockedStreams
      ? is.streamLockedNow
      : false

  const onSelect = async (val: TieredItemValue) => {
    try {
      tieredList.current.hide()
      if (val.type === 'lock') {
        await locked(val.periodInSeconds)
        return
      }

      if (val.type === 'unlock') {
        await unlocked()
        return
      }

      throw new Error('Unknown select lock type')
    } catch (e) {
      const error = e as Error
      console.error(error)
      const isOtherUserBlocked = error?.message.includes('locked by another user')
      toastRef.current?.show({
        severity: 'error',
        detail: isOtherUserBlocked
          ? locale.alreadyLockError
          : locale.changeLockError,
        summary: locale.commonError,
        life: 5000,
      })
    }
  }

  const canIOnlyLockControl = Boolean(canI.changeLockedControls && !canI.watchLockedStreams)
  const menuModel = useMemo(() => buildMenu(canIOnlyLockControl, locale, lockFeatureSettings?.menu), [
    canIOnlyLockControl,
    locale,
    lockFeatureSettings?.menu,
  ])
  if (!canI.someLocked) return null

  const canShowTimer = !hideTimer && isBlockedStatus && !isTimeLeft

  return (
    <React.Fragment>
      {createPortal(<Toast ref={globalToast} position="bottom-left" />, document.body)}
      <div css={wrapperListStyle}>
        <TieredList
          reference={tieredList}
          onSelect={onSelect}
          model={!isBlockedStatus ? menuModel.lock : menuModel.unlock}
        />
        <Button
          disabled={isLoading}
          onClick={(e) => tieredList.current.toggle(e)}
          css={css`
            &.p-button.button-block-camera {
              color: var(${isBlockedStatus ? '--error-color' : '--text-color-active'});
            }

            &.p-button.button-block-camera:enabled:hover {
              color: var(--error-color);
            }

            &.p-button.p-button-blurred.button-block-camera {
              font-size: 18px;
              color: var(${isBlockedStatus ? '--error-color' : '--text-color'});
            }

            &.p-button.p-button-text.p-button-plain {
              padding: 0;
              width: calc(32rem / var(--bfs));
              height: calc(32rem / var(--bfs));
              font-size: calc(28rem / var(--bfs));
            }
          `}
          className={`${buttonClassName} button-block-camera`}
          icon={`mdi  ${isBlockedStatus ? `${iconsClass.lock}` : `${iconsClass.unlock}`}`}
          iconPos="right"
        />
        {canShowTimer && (
          <span css={lockTimeLeftStyle}>
            {hours}:{minutes}:{seconds}
          </span>
        )}
      </div>
    </React.Fragment>
  )
}
